<template>
  <div class="cont-warp">
    <!--面包屑-->
    <div class="crumbs-cont">
      <div class="crumbs">所在位置：
        <router-link to="/">首页</router-link>
        /
        <router-link to="/securityTrain"> 证书培训</router-link>
        / 报名缴费
      </div>
    </div>
    <!--nav-->
    <div class="nav-cont">
      <div class="nav-cont-box">
        <h2 class="nav-title">数据安全领航计划</h2>
        <p class="nav-p">为落实数据安全保护要求,培养更多数据安全人才,“数据安全领航计划“开启了未来数据安全人才专场培训,</p>
        <p class="nav-p">特开展注册<span style="font-weight: bold">数据安全官、注册数据安全工程师和DSMM测评师</span>人才培训。</p>
        <p class="nav-time"><span class="time">近期开班日期:2022年10月20日-10月22日</span></p>
        <!--三个证书-->
        <div class="certificate-introduced-cont">
          <div class="certificate-cont">
            <div class="certificate-item">
              <p class="certificate-title back01">注册数据安全官</p>
              <p class="certificate-desc">着眼于数据安全顶层规划设计与统筹管理，旨在培养业界一流的<span style="font-weight: bold">数据安全领导者</span></p>
            </div>
            <div class="certificate-item">
              <p class="certificate-title back02">注册数据安全工程师</p>
              <p class="certificate-desc">着眼于数据安全方案的落地实施，旨在培养专业的<span style="font-weight: bold">数据安全工程技术人才</span></p>
            </div>
            <div class="certificate-item">
              <p class="certificate-title back03">DSMM测评师</p>
              <p class="certificate-desc">着眼于DSMM(数据安全能力成熟度模型)测试评估，旨在培养专业的<span style="font-weight: bold">DSMM测评师</span>
              </p>
            </div>
          </div>
          <p class="nav-p">数据安全官、数据安全工程师培训证书由大数据协同安全技术国家工程研究中心和工业与信息化部人才交流中心发放,一考双证。</p>
          <p class="nav-p">DSMM测评师培训证书由贵州大数据安全工程研究中心和工业与信息化部人才交流中心发放,一考双证。</p>
        </div>
      </div>
    </div>
    <!-- 报名通道-->
    <div class="apply-form-cont">
      <div class="apply-form">
        <div class="apply-title">
          <h2>请详细填写以下信息,方便我们与您联系</h2>
          <span class="apply-tip"><img
              src="../../../assets/images/securityTrain/pc/payCost/icon02.png"/>在此通道报名,前200名有优惠。</span>
          <div class="form-cont">
            <el-form :rules="rules" ref="form" :model="form" label-width="130px">
              <el-form-item label="您的姓名" prop="realName">
                <el-input v-model="form.realName" placeholder="请输入"></el-input>
              </el-form-item>
              <el-form-item label="您的电话" prop="mobile">
                <el-input v-model="form.mobile" placeholder="请输入" ref="mobileInput"></el-input>
              </el-form-item>
              <el-form-item label="验证码" prop="refereeName">
                <el-input v-model="form.refereeName" placeholder="请输入"></el-input>
                <span class="getCode-btn" @click="getCode()">{{ verificationCode }}</span>
              </el-form-item>
              <el-form-item label="您要报考的证书" prop="certificateType">
                <el-radio-group v-model="form.certificateType" v-for="(item, index) in certificateTypeList"
                                @change="radioChange(item)">
                  <el-radio :label="item.certName"></el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="待缴费金额">
                <el-input v-model="form.totalPrice" placeholder="请输入" disabled></el-input>
              </el-form-item>
              <div class="cost-tip">
                <span class="tip"><img src="../../../assets/images/securityTrain/pc/payCost/icon02.png"/>
                  说明:请在缴费信息处备注姓名。</span>
              </div>
              <div class="cost-btn">
                <el-button type="primary" class="pay-cost-btn" @click="submitForm(form)">去缴费</el-button>
                <el-button class="" @click="openMsg()">在线咨询</el-button>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!--扫码支付-->
    <pay-qr-code ref="childOpen" :codeUrl="codeUrl" :orderNo="orderNo"></pay-qr-code>
  </div>
</template>

<script>
import {
  orderAdd,
  dataSecurityCertificateList,
  getSendIdentityCode,
  getVerifyCode,
  dataSecurityCertificateInfo, landingPageOrder
} from '../../../apis/apis'
import PayQrCode from "./payQrCode"

export default {
  name: 'PayCost',
  components: {
    PayQrCode
  },
  data() {
    return {
      form: {
        mobile: "",
        realName: "",
        certificateType: "", //证书类型
        totalPrice: "", //缴费金额
        refereeName: "",//获取验证码
      },
      count: '',
      status: true,
      timer: 60,
      verificationCode: "获取验证码",
      codeUrl: "",//付款二维码
      orderNo: "", //订单编号
      certificateTypeList: [], //获取数据安全类型数组
      rules: {
        realName: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        mobile: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        refereeName: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        certificateType: [
          {required: true, message: '请选择报考证书', trigger: 'blur'},
        ],
      }
    }
  },
  methods: {
    //在线咨询弹框打开
    openMsg() {
      blzx.connenct(0);
    },
    //点击按钮改变事件
    radioChange(item) {
      this.form.totalPrice = item.entryFee * item.discount;
    },
    //去报名按钮
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.getVerifyCode();
        } else {
        }
      });
    },
    // 确认人民币付款
    async confirmRmbPay() {
      const params = {
        mobile: this.form.mobile,
        realName: this.form.realName,
        totalPrice: this.form.totalPrice,
        // totalPrice: 0.01,
        json: JSON.stringify({证书类型: this.form.certificateType}),
        paid: true,
        type: 3,
        isChannel: "3",
      };
      orderAdd(params).then((res) => {
        if (res.data.code === 200) {
          this.codeUrl = res.data.data.jsConfig.codeUrl;
          if (this.codeUrl) {
            this.$refs.childOpen.open();
            this.orderNo = res.data.data.orderNo; // 把订单号传给子组件
          }
        } else {
        }
      }).catch(() => {
      });
    },
    // 获取证书安全的类型
    async getDataSecurityCertificateList() {
      try {
        let {data} = await dataSecurityCertificateList({})
        if (data.code == 1000) {
          this.certificateTypeList = data.data.content;
        } else {
          this.$message.error(data.message);
        }
      } catch (err) {
        console.log(err);
      }
    },
    getCode() {
      if (this.form.mobile == "") {
        this.$message.error("手机号码不能为空");
        this.$refs.mobileInput.focus();
      } else {
        // this.$refs.codeInput.focus();
        this.getSendIdentityCode();
      }
    },
    async getSendIdentityCode() { //获取验证码接口
      try {
        let param = {
          "mobile": this.form.mobile
        }
        let {
          data
        } = await getSendIdentityCode(param);
        if (data.code == 1000) {
          if (this.status) {
            this.status = false;
            var count = this.timer;
            this.verificationCode = count + "s后获取";
            var timer = setInterval(() => {
              if (count === 0) {
                clearInterval(timer);
                this.verificationCode = "获取验证码";
                this.status = true;
              } else {
                count--;
                this.verificationCode = count + "s后获取";
              }
            }, 1000);
          }
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    },
    //验证短信验证码是否正确
    async getVerifyCode() {
      try {
        let param = {
          "mobile": this.form.mobile,
          "mobileCode": this.form.refereeName,
        }
        let {
          data
        } = await getVerifyCode(param);
        if (data.code == 1000) {
          this.confirmRmbPay(); // 付款
        } else {
        }
      } catch (err) {
        console.log(err);
      }
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('/h5PayCostPage');
    } else {
      this.$router.replace('/payCostPage');
      this.getDataSecurityCertificateList();
    }
  },
}
</script>
<style>
.h5-pay-cost-page {
  display: none;
}
</style>
<style lang="less" scoped>
//面包屑
.crumbs-cont {
  text-align: left;
  width: 100%;
  line-height: 34px;
  height: 34px;
  background: #F3F6F8;

  .crumbs {
    width: 1200px;
    margin: 0 auto;

    a {
      font-size: 14px;
      color: #333333;

      &:hover {
        color: #0079F4;
      }
    }

    font-size: 14px;
    color: #333333;
  }
}

//nav
.nav-cont {
  width: 100%;
  height: auto;
  background: #FFFFFF;
  padding: 55px 0;

  .nav-cont-box {
    width: 1200px;
    margin: 0 auto;

    .nav-title {
      font-size: 30px;
      font-weight: bold;
      color: #000000;
      height: 50px;
    }

    .nav-p {
      font-size: 16px;
      color: #333333;
      line-height: 36px;
    }

    .nav-time {
      display: inline-block;
      margin-top: 26px;
      padding: 15px 20px;
      background: #ECF5FF;
      border: 1px solid #BFDFFF;
      border-radius: 22px;
      font-size: 18px;
      font-weight: bold;
      color: #2F96FF;

      .time {
        &:before {
          width: 19px;
          height: 22px;
          content: "";
          display: inline-block;
          background: url("../../../assets/images/securityTrain/pc/payCost/icon01.png");
          vertical-align: middle;
          margin-right: 8px;
        }
      }
    }
  }
}

//证书
.certificate-introduced-cont {
  .certificate-cont {
    display: flex;
    justify-content: space-between;
    margin: 37px 0 52px 0;

    .certificate-item {
      width: 32%;

      .certificate-title {
        padding: 20px 0;
        border-radius: 10px 10px 0px 0px;
        font-size: 24px;
        font-weight: bold;
        color: #FFFFFF;
      }

      .back01 {
        background: #2F96FF;
      }

      .back02 {
        background: #FF8F2C;
      }

      .back03 {
        background: #FF6666;
      }

      .certificate-desc {
        background: #FFFFFF;
        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 50px 20px;
        font-size: 16px;
        color: #333333;
        line-height: 31px;;
      }
    }
  }
}

//报名通道
.apply-form-cont {
  width: 100%;
  height: 691px;
  background: #F3F7FA;

  .apply-form {
    padding-top: 64px;

    .apply-title {
      h2 {
        font-size: 20px;
        font-weight: bold;
        color: #000000;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .apply-tip {
      font-size: 12px;
      color: #FF2A2A;
      padding: 5px;
      background: #FFEFEF;
      border: 1px solid #FFBEBE;
      border-radius: 4px;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;

      img {
        margin-right: 5px;
      }
    }
  }
}

//表单
.form-cont {
  text-align: left;
  width: 790px;
  margin: 30px auto 0;

  /deep/ .el-input {
    width: 540px;
  }

  /deep/ .el-radio {
    width: 540px;
    line-height: 30px;
  }

  .cost-tip {
    position: relative;

    .tip {
      margin-left: 130px;
      font-size: 12px;
      color: #FF2A2A;
      padding: 5px 0;
      text-indent: 5px;
      width: 540px;
      background: #FFEFEF;
      border: 1px solid #FFBEBE;
      border-radius: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .getCode-btn {
    position: relative;
    right: 90px;
    font-size: 15px;
    color: #2F78FF;
    top: 2px;
    cursor: pointer;
  }

  //提交按钮
  .cost-btn {
    padding-left: 130px;
    margin-top: 24px;


    .pay-cost-btn {
      background: #0079F4;
      border-radius: 4px;
    }
  }
}

</style>
