<template>
  <div>
    <el-dialog
        title="缴费提醒"
        :visible.sync="dialogFormVisible"
        width="40%"
        center>
      <!--生成二维码-->
      <div class="erweima">
        <div class="qrcode" ref="qrCodeUrl" id="qrcode"></div>
        <p style="margin-top: 10px">微信扫码可进行支付！</p>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible = false">取 消</el-button>
        <!--    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>-->
  </span>
    </el-dialog>
  </div>
</template>
<script>
import QRCode from 'qrcodejs2'
import {landingPageOrder} from "../../../apis/apis"

export default {
  props: {
    codeUrl: {
      type: String,
    },
    orderNo: {
      type: String,
    }
  },
  data() {
    return {
      dialogFormVisible: false
    };
  },
  destroyed() {
    clearInterval(this.myInterval);
    clearTimeout(this.createQrcode);
  },
  methods: {
    open() { // 在父组件调用打开
      this.dialogFormVisible = true;
      this.creatQrCode();
    },
    creatQrCode() {
      let that = this;
      //二维码的生成
      this.createQrcode = setTimeout(() => { //解决弹框第一次点击二维码不出现问题
        document.getElementById("qrcode").innerHTML = ""; //解决多次点击按钮生成多个二维码问题
        let qrcode = new QRCode(that.$refs.qrCodeUrl, {
          text: this.codeUrl, // 需要转换为二维码的内容
          width: 150,
          height: 150,
          colorDark: '#000000',
          colorLight: '#fff',
          correctLevel: QRCode.CorrectLevel.H
        })
      }, 100);
      this.myInterval = window.setInterval(() => {
        setTimeout(() => {
          this.orderInfo(this.orderNo); //调用接口的方法
        }, 1)
      }, 3000);
    },
    //获取订单页详情
    orderInfo(orderNo) {
      landingPageOrder(orderNo).then((data) => {
        if (data.data.code === 200) {
          if (data.data.data.paid == true) {
            this.$message.success("缴费成功！");
            this.dialogFormVisible = false;
            clearInterval(this.myInterval);
          } else {
            // this.$message.error("缴费失败！");
          }
        } else {
        }
      }).catch(() => {
      });
    },
  },
  mounted() {
  }
};
</script>
<style lang="less">
.erweima {
  width: 100%;
  // display: flex;
  text-align: center;

  .qrcode {
    margin: 0 auto;
    display: table;
  }

  p {
    margin: 0 auto;
  }
}
</style>
